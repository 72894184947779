<template>
  <t-text-field
    :id="field"
    v-model="value"
    v-bind="{...$attrs}"
    :class="[`field-${field}`]"
    name="address-postal"
    :autocomplete="autocomplete"
    :placeholder="placeholder"
    :error-messages="errors"
    outlined
    required
    :loading="loading"
    :success="isValid"
    @blur="validate(value)"
  />
</template>

<script>
import PostalField from 'chimera/all/components/form/fields/postal/PostalField'

export const field = 'address-postal'
export default {
  name: 'AddressPostalRadioField',

  extends: PostalField,

  props: {
    field: {
      type: String,
      default: field
    },

    autocomplete: {
      type: String,
      default: 'postal-code'
    }
  }
}
</script>
