<template>
  <div>
    <div class="form-row border-b-0">
      <NameField
        :label="$t('field.name.label')"
        placeholder=""
        v-on="$listeners"
      />
    </div>

    <AddressFormGroup v-on="$listeners" />

    <div class="form-row border-t-0">
      <PhoneField
        :label="$t('field.phone.label')"
        placeholder=""
        v-on="$listeners"
      />
    </div>

    <div class="form-row">
      <EmailField
        :label="$t('field.email.label')"
        placeholder=""
        v-on="$listeners"
      />
    </div>

    <div class="form-row">
      <CommentsField
        :is-optional="true"
        :label="$t('field.comments.label')"
        placeholder=""
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script>
import NameField from 'chimera/all/components/form/fields/name/NameField'
import EmailField from 'chimera/all/components/form/fields/email/EmailField'
import CommentsField from 'chimera/all/components/form/fields/comments/CommentsField'
import PhoneField from 'chimera/all/components/form/fields/phone/PhoneField'
import AddressFormGroup from 'chimera/all/themes/blueflow/components/form/part/address/AddressFormGroup'

export default {
  name: 'ContactInformationFormPart',

  components: {
    NameField,
    EmailField,
    CommentsField,
    PhoneField,
    AddressFormGroup
  }
}
</script>
