<template>
  <t-textarea
    :id="field"
    v-model="value"
    v-bind="{...$attrs}"
    :class="[`field-${field}`]"
    :error-messages="errors"
    :autofocus="autofocus"
    :placeholder="placeholder"
    :loading="loading"
    :success="isValid"
    hide-icons
    @blur="validate(value)"
  />
</template>

<script>
import AbstractFormField from 'chimera/all/components/form/fields/AbstractFormField'
import ValidationError from 'chimera/all/functions/ValidationError'

export const field = 'comments'
export default {
  name: 'CommentsField',

  extends: AbstractFormField,

  props: {
    field: {
      type: String,
      default: field
    },

    placeholder: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    value: ''
  }),

  watch: {
    /**
     * V-model
     *
     * @param {string} value
     */
    value (value) {
      this.reset()
    }
  },

  methods: {
    /**
     * @returns {*[]}
     */
    customErrorMap () {
      return [
        { id: ValidationError.reasonIsRequired, message: this.$i18n.t('field.comments.validation.required') }
      ]
    }
  }
}
</script>
