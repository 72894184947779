<template>
  <div class="usps">
    <div v-for="usp in usps" :key="usp.title" class="usp" :class="uspClass">
      <div class="usp__icon-container">
        <InlineSvg v-if="usp.logo" class="usp__icon" :src="usp.logo" :alt="usp.title || usp.text" />
      </div>

      <h2 v-if="usp.title" class="usp__title">
        <strong>{{ usp.title }}</strong>
      </h2>

      <p v-if="usp.text" class="usp__text">
        {{ usp.text }}
      </p>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'Usps',

  components: {
    InlineSvg
  },

  props: {
    usps: {
      type: Array,
      required: true
    },

    columns: {
      type: Number,
      default: 3
    }
  },

  computed: {
    /**
     * @returns {string}
     */
    uspClass () {
      // PurgeCss: sm:w-1/2 sm:w-1/3 sm:w-1/4 sm:w-1/5 sm:w-1/6
      return `w-full sm:w-1/${this.columns}`
    }
  }
}
</script>
