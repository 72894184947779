<template>
  <div />
</template>

<script>
import AbstractFormField from 'chimera/all/components/form/fields/AbstractFormField'
import usesLeadPost from 'chimera/all/mixins/usesLeadPost'

export const field = 'submit'
export default {
  name: 'LeadSubmitFormPartPD6492',

  extends: AbstractFormField,

  mixins: [
    usesLeadPost
  ],

  props: {
    field: {
      type: String,
      default: field
    }
  },

  /**
   * @returns {object}
   */
  data () {
    return {
      value: undefined
    }
  },

  methods: {
    /**
     * @param {*} value
     */
    afterOnValidLeadPost (value) {
      this.value = true
      this.$emit('leadPostValid', value)
    },

    /**
     * @param {object} errorData
     */
    afterOnInvalidLeadPost (errorData) {
      this.value = false
      this.$emit('leadPostInvalid', errorData)
    },

    /**
     * @param {Error} error
     */
    afterOnErrorLeadPost (error) {
      this.$emit('leadPostError', error)
    },

    /**
     * Before lead post
     * Do not track event if transactionId is empty since we will not be able to match the requests
     * TransactionId can also be empty when the lead was not created like double (fingerprint test)
     *
     * @param {object} object
     * @param {string} object.id
     */
    beforeOnValidLeadPost ({ id }) {
      if (id === undefined) {
        return
      }

      const token = this.$store.getters['lead/getData']('recaptcha-token', undefined)
      this.$eventBus.emitRecaptchaTokenLengthEvent(token === undefined ? 0 : token.length, id)
    }
  }
}
</script>
