<template>
  <section>
    <div class="form-row space-x-4">
      <div class="w-2/3">
        <AddressPostalRadioField
          ref="addressPostal"
          :label="$t('field.postal.label')"
          placeholder=""
          :init-with-store-key="initPostalWithStoreKey"
          @result="onResult"
          v-on="$listeners"
        />
      </div>

      <div class="w-1/3">
        <AddressNumberField
          ref="addressNumber"
          :label="$t('field.address-number.label')"
          placeholder=""
          @result="onResult"
          v-on="$listeners"
        />
      </div>
    </div>

    <div class="form-row">
      <AddressStreetField
        ref="addressStreet"
        :label="$t('field.address-street.label')"
        placeholder=""
        autocomplete="no-fill"
        @result="onResult"
        v-on="$listeners"
      />
    </div>

    <div class="form-row">
      <AddressCityField
        ref="addressCity"
        :label="$t('field.address-city.label')"
        placeholder=""
        autocomplete="no-fill"
        @result="onResult"
        v-on="$listeners"
      />
    </div>
  </section>
</template>

<script>
import AddressFormGroup from 'chimera/all/components/form/parts/address/AddressFormGroup'
import AddressPostalRadioField from 'chimera/all/components/form/fields/addressPostal/AddressPostalRadioField'
import AddressNumberField from 'chimera/all/components/form/fields/addressNumber/AddressNumberField'
import AddressStreetField from 'chimera/all/components/form/fields/addressStreet/AddressStreetField'
import AddressCityField from 'chimera/all/components/form/fields/addressCity/AddressCityField'

export default {
  name: 'AddressFormGroup',

  components: {
    AddressPostalRadioField,
    AddressNumberField,
    AddressStreetField,
    AddressCityField
  },

  extends: AddressFormGroup
}
</script>
