<template>
  <t-text-field
    :id="field"
    v-model="value"
    v-bind="{...$attrs}"
    :class="[`field-${field}`]"
    name="email"
    :autocomplete="autocomplete"
    :error-messages="errors"
    :placeholder="placeholder"
    outlined
    :autofocus="autofocus"
    :loading="loading"
    type="email"
    inputmode="email"
    :success="isValid"
    @blur="validate(value)"
  />
</template>

<script>
import ValidationRules from 'chimera/all/functions/ValidationRules'
import ValidationError from 'chimera/all/functions/ValidationError'
import AbstractFormField from 'chimera/all/components/form/fields/AbstractFormField'

export const field = 'email'
export default {
  name: 'EmailField',

  extends: AbstractFormField,

  props: {
    field: {
      type: String,
      default: field
    },

    autocomplete: {
      type: String,
      default: 'email'
    },

    placeholder: {
      type: String,
      default: 'E-mailadres'
    }
  },

  data: () => ({
    value: ''
  }),

  watch: {
    /**
     * V-model
     *
     * @param {string} value
     */
    value (value) {
      this.reset()
    }
  },

  methods: {
    /**
     * @returns {Array}
     */
    validationRules () {
      return ValidationRules.getRules([
        ...ValidationRules.isEmailFormat()
      ])
    },

    /**
     * @returns {*[]}
     */
    customErrorMap () {
      return [
        { id: ValidationError.reasonIsRequired, message: this.$i18n.t('field.email.validation.required') },
        { id: ValidationError.reasonInvalidRegex, message: this.$i18n.t('field.email.validation.invalid') },
        { id: ValidationError.reasonInvalidLength, message: this.$i18n.t('field.email.validation.invalid') },
        { id: ValidationError.reasonInvalidValue, message: this.$i18n.t('field.email.validation.invalid') }
      ]
    }
  }
}
</script>
