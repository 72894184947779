<template>
  <div class="page-error prose">
    <div class="container">
      <h1 class="error__title">
        {{ $t('partTitle') }}
      </h1>

      <p class="error__message">
        {{ $t('text') }}
      </p>

      <TBtnLink
        id="backToHome"
        class="error__back-btn"
        to="/"
      >
        {{ $t('button') }}
      </TBtnLink>
    </div>
  </div>
</template>

<script>
import AbstractPage from 'chimera/all/components/page/AbstractPage'

export default {
  name: 'ErrorPage',

  extends: AbstractPage,

  layout: 'content',

  /**
   * @returns {object}
   */
  data () {
    return {
      headTitle: undefined,
      path: '/error'
    }
  },

  /**
   */
  created () {
    this.headTitle = this.$t('title')
  }
}
</script>

<i18n>
{
  "nl-NL": {
    "title": "Sorry, er is blijkbaar iets mis gegaan",
    "partTitle": "Sorry! Er is iets niet helemaal goed gegaan.",
    "text": "Klik op de knop hieronder, dan ga je terug naar de homepage van onze website.",
    "button": "Terug naar de homepage"
  },
  "nl-BE": {
    "title": "Sorry, er is blijkbaar iets mis gegaan",
    "partTitle": "Sorry! Er is iets niet helemaal goed gegaan.",
    "text": "Klik op de knop hieronder, dan gaat u terug naar de homepage van onze website.",
    "button": "Terug naar de homepage"
  },
  "es-ES": {
    "title": "Lo siento, aparentemente algo salió mal",
    "partTitle": "¡Lo siento! Algo salió mal.",
    "text": "Haga clic en el botón de abajo para volver a la página de inicio de nuestro sitio web.",
    "button": "Volver a la página de inicio"
  },
  "de-DE": {
    "title": "Entschuldigung, anscheinend ist ein Fehler aufgetreten",
    "partTitle": "Es tut uns leid! Es ist ein Fehler aufgetreten.",
    "text": "Klicken Sie auf den nachstehenden Button und Sie kehren zur Startseite unserer Website zurück.",
    "button": "Zurück zur Startseite"
  },
  "fr-BE": {
    "title": "Désolé, quelque chose semble mal tourner",
    "partTitle": "Désolé! Quelque chose semble mal tourner.",
    "text": "Cliquez sur le bouton ci-dessous et vous retournerez à la page d'accueil de notre site Web.",
    "button": "Retour à la page d'accueil"
  },
  "fr-FR": {
    "title": "Désolé, quelque chose semble mal tourner",
    "partTitle": "Désolé! Quelque chose semble mal tourner.",
    "text": "Cliquez sur le bouton ci-dessous et vous retournerez à la page d'accueil de notre site Web.",
    "button": "Retour à la page d'accueil"
  },
  "it-IT": {
    "title": "Scusa, sembra che qualcosa non sia andato per il verso giusto",
    "partTitle": "Scusa! C'è qualcosa che non ha funzionato del tutto.",
    "text": "Clicca sul pulsante qui sotto, in questo modo torni alla homepage del nostro sito.",
    "button": "Torna alla homepage"
  }
}
</i18n>
