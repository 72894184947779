<template>
  <section>
    <div class="flex flex-wrap">
      <div class="w-1/2 md:w-2/3 pr-2 py-0">
        <AddressPostalRadioField
          ref="addressPostal"
          :init-with-store-key="initPostalWithStoreKey"
          @result="onResult"
          v-on="$listeners"
        />
      </div>

      <div class="w-1/2 md:w-1/3 pl-2 py-0">
        <AddressNumberField
          ref="addressNumber"
          @result="onResult"
          v-on="$listeners"
        />
      </div>

      <AddressStreetField
        v-show="showStreetAndCity"
        ref="addressStreet"
        class="w-full"
        autocomplete="no-fill"
        @result="onResult"
        v-on="$listeners"
      />
      <AddressCityField
        v-show="showStreetAndCity"
        ref="addressCity"
        class="w-full"
        autocomplete="no-fill"
        @result="onResult"
        v-on="$listeners"
      />
    </div>
  </section>
</template>

<script>
import AddressPostalRadioField, { field as addressPostalField } from 'chimera/all/components/form/fields/addressPostal/AddressPostalRadioField.vue'
import AddressNumberField from 'chimera/all/components/form/fields/addressNumber/AddressNumberField'
import AddressStreetField from 'chimera/all/components/form/fields/addressStreet/AddressStreetField'
import AddressCityField from 'chimera/all/components/form/fields/addressCity/AddressCityField'
import usesAddress from 'chimera/all/mixins/usesAddress'

export default {
  name: 'AddressFormGroup',

  components: {
    AddressPostalRadioField,
    AddressNumberField,
    AddressStreetField,
    AddressCityField
  },

  mixins: [
    usesAddress
  ],

  props: {
    initPostalWithStoreKey: {
      type: String,
      default: 'postal'
    },

    initHiddenStreetAndCity: {
      type: Boolean,
      default: true
    }
  },

  /**
   * @returns {object}
   */
  data () {
    return {
      showStreetAndCity: false,
      lastResult: undefined
    }
  },

  watch: {
    /**
     * Update on prop change
     *
     * @param {boolean} hidden
     */
    initHiddenStreetAndCity (hidden) {
      this.showStreetAndCity = !hidden
    }
  },

  /**
   */
  created () {
    this.showStreetAndCity = !this.initHiddenStreetAndCity
  },

  methods: {
    /**
     * Init the lookup of street and city
     *
     * @param {object} result
     * @param {boolean} result.isValid
     * @param {string} result.value
     */
    onPostalResult ({ isValid, value }) {
      if (isValid === this.lastResult) {
        return
      }

      this.lastResult = isValid

      // TODO: fetchStreetAndCityForPostal should work for all our countries - https://yonego.atlassian.net/browse/PD-1518
      const shouldFetchAddress = isValid && this.country === 'nl'
      if (shouldFetchAddress) {
        this.resetPostalStreetAndCity()
        this.fetchStreetAndCityForPostal(value)
      }
    },

    /**
     * Bubbles up the result emit from child to step
     * Triggers onPostalResult for postal fields
     *
     * @param {object} result
     * @param {object} result.field
     * @param {boolean} result.isValid
     * @param {string} result.value
     */
    onResult ({ field, isValid, value }) {
      if (field === addressPostalField) {
        this.onPostalResult({ isValid, value })
      }
    },

    /**
     * On data found
     *
     * @param {object} data
     */
    dataFound (data) {
      this.showStreetAndCity = false

      if (this.$refs.addressStreet && this.$refs.addressCity) {
        this.$refs.addressStreet.setValue(data.Street)
        this.$refs.addressCity.setValue(data.CityName)
      }
    },

    /**
     * On data not found
     */
    dataNotFound () {
      this.resetPostalStreetAndCity(true)
    },

    /**
     * Resets component state and vuex store data for street name, city and (as required) postal
     * Triggers the display of the additional input fields for street and city
     *
     * @param {boolean} showAddressFields
     */
    resetPostalStreetAndCity (showAddressFields = !this.initHiddenStreetAndCity) {
      this.$refs.addressStreet.clearValue()
      this.$refs.addressCity.clearValue()

      // Set showStreetAndCity which triggers the display of additional input fields for street and city
      this.showStreetAndCity = showAddressFields
    }
  }
}
</script>
