import { EventBus } from 'chimera/all/plugins/eventbus'
import ValidationError from './ValidationError'

/**
 * @returns {Array}
 */
function defaultValidationRules () {
  return [
    ...isRequired()
  ]
}

/**
 * @returns {*[]}
 *
 * @param {Array} customRules
 */
function getRules (customRules = []) {
  const defaultRules = [
    ...defaultValidationRules()
  ]

  return defaultRules.concat(customRules)
}

/**
 * @param {string}  email
 *
 * @returns {boolean}
 */
function isEmailFormatFn (email) {
  return /^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/.test(email)
}

/**
 * @param {string}  errorId
 *
 * @returns {boolean}
 */
function isEmailFormat (errorId = ValidationError.reasonInvalidRegex) {
  return [
    value => isEmailFormatFn(value) || errorId
  ]
}

/**
 * @type {RegExp}
 */
const DutchPostalRegex = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i

/**
 * @param {string} value
 * @returns {boolean}
 */
function isNLPostalFormatFn (value) {
  return DutchPostalRegex.test(value)
}

/**
 * @type {RegExp}
 */
const BelgiumPostalRegex = /^[1-9]{1}[0-9]{3}$/

/**
 * @param {string} value
 * @returns {boolean}
 */
function isBEPostalFormatFn (value) {
  return BelgiumPostalRegex.test(value)
}

/**
 * @type {RegExp}
 */
const SpainPostalRegex = /^[0-9]{5}$/

/**
 * @param {string} value
 * @returns {boolean}
 */
function isESPostalFormatFn (value) {
  return SpainPostalRegex.test(value)
}

/**
 * @type {RegExp}
 */
const GermanyPostalRegex = /^[0-9]{5}$/

/**
 * @param {string} value
 * @returns {boolean}
 */
function isDEPostalFormatFn (value) {
  return GermanyPostalRegex.test(value)
}

/**
 * @type {RegExp}
 */
const FrancePostalRegex = /^[0-9]{5}$/

/**
 * @param {string} value
 * @returns {boolean}
 */
function isFRPostalFormatFn (value) {
  return FrancePostalRegex.test(value)
}

/**
 * @type {RegExp}
 */
const ItalyPostalRegex = /^[0-9]{5}$/

/**
 * @param {string} value
 * @returns {boolean}
 */
function isITPostalFormatFn (value) {
  return ItalyPostalRegex.test(value)
}

/**
 * @param {string} country
 * @param {string} value
 * @throws Error
 * @returns {boolean}
 */
function isPostalFormatFn (country, value) {
  // Replace any spaces before validation.
  const trimmedValue = value.replace(/\s/g, '')
  switch (country) {
    case 'be':
      return isBEPostalFormatFn(trimmedValue)
    case 'de':
      return isDEPostalFormatFn(trimmedValue)
    case 'es':
      return isESPostalFormatFn(trimmedValue)
    case 'fr':
      return isFRPostalFormatFn(trimmedValue)
    case 'it':
      return isITPostalFormatFn(trimmedValue)
    case 'nl':
      return isNLPostalFormatFn(trimmedValue)
  }

  throw new Error(`isPostalFormatFn not implemented for country ${country}`)
}

/**
 * @param {string} country
 * @param {string} errorId
 * @returns {Function[]}
 */
function isPostalFormat (country, errorId = ValidationError.reasonInvalidRegex) {
  return [
    value => isPostalFormatFn(country, value) || errorId
  ]
}

/**
 * @param {string}  value
 * @param {number}  length
 *
 * @returns {boolean}
 */
function isLengthFn (value, length) {
  const trimmedValue = value.replace(/\s/g, '')
  // .length is not multibyte safe.
  return trimmedValue.length === length
}

/**
 * @param {number}  length
 * @param {string}  errorId
 *
 * @returns {(function(*=): boolean|string)[]}
 */
function isLength (length, errorId = ValidationError.reasonInvalidLength) {
  return [
    value => isLengthFn(value, length) || errorId
  ]
}

/**
 * @param {string}  value
 * @param {number}  length
 *
 * @returns {boolean}
 */
function isMinimalLengthFn (value, length) {
  // .length is not multibyte safe.
  return (value.length >= length)
}

/**
 * @param {number}  length
 * @param {string}  errorId
 *
 * @returns {(function(*=): boolean|string)[]}
 */
function isMinimalLength (length, errorId = ValidationError.reasonInvalidLength) {
  return [
    value => isMinimalLengthFn(value, length) || errorId
  ]
}

/**
 * @param {any}  value
 *
 * @returns {boolean}
 */
function isRequiredFn (value) {
  if (Array.isArray(value)) {
    return value.filter(val => isRequiredFn(val)).length > 0
  }

  // Trim if value is a string
  if (typeof value === 'string') {
    value = value.trim()
  }

  return !!value
}

/**
 * @param {string}  errorId
 *
 * @returns {boolean}
 */
function isRequired (errorId = ValidationError.reasonIsRequired) {
  return [
    value => isRequiredFn(value) || errorId
  ]
}

/**
 * Evaluate validation rules
 *
 * @param {Array} rules
 * @param {string[]} value
 *
 * @returns {Array}
 */
function evaluate (rules, value) {
  const errors = []
  rules.forEach((rule) => {
    const valid = typeof rule === 'function' ? rule(value) : rule

    if (valid === false || typeof valid === 'string') {
      errors.push(valid || '')
    } else if (typeof valid !== 'boolean') {
      // This situation is a development error and should be tracked in our debugging software.
      EventBus.emitErrorAppErrorEvent(
        new Error('ValidationRules.evaluate: unexpected rule return value'), { type: typeof valid }
      )
    }
  })

  return errors
}

export default {
  defaultValidationRules,
  evaluate,
  getRules,
  isEmailFormat,
  isPostalFormat,
  isLength,
  isMinimalLength,
  isRequired
}
