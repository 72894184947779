/**
 * Mixin to be used color support.
 *
 * @returns {object}
 */
export default {
  computed: {
    /**
     * @returns {string}
     */
    country () {
      return this.$store.getters['context/get']('country', 'nl')
    }
  },

  methods: {
    /**
     * @param {string} postal
     * @returns {Promise<void>}
     */
    async fetchStreetAndCityForPostal (postal) {
      // TODO: https://yonego.atlassian.net/browse/PD-1518
      if (this.country !== 'nl') {
        this.$eventBus.emitErrorAppErrorEvent(new Error('This country does not support fetchStreetAndCityForPostal'), { postal, country: this.country })
        return
      }

      postal = postal.replace(/\s/g, '').toLowerCase()
      const response = await this.fetchDataForPostal(postal)
      if (!response.ZipCodeData.Error) {
        await this.dataFound(response.ZipCodeData[0])
        return response.ZipCodeData[0]
      }

      // The error text is only thing to match against to detect a not found.
      // Any other error text indicates something went badly wrong.
      if (response.ZipCodeData.Error.includes('could not be found')) {
        this.$eventBus.emitPostalNotAutoCompletedEvent(this.country, postal)
      } else {
        const error = new Error(response.ZipCodeData.Error)
        this.$eventBus.emitErrorAppErrorEvent(error, { country: this.country, postal })
      }

      this.dataNotFound()
      return undefined
    },

    /**
     * Postal GET request
     *
     * @param {string} postal
     * @returns {Promise<object>}
     */
    fetchDataForPostal (postal) {
      return this.$axios.$get(`${process.env.postalApiUrl}?ZipCode=${postal}`, {
        headers: {
          Authorization: 'Bearer 560a1e3d-44ec-4be2-8945-7895b8061e7d',
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).catch((error) => {
        this.dataNotFound()
        // Push unexpected error and meta data on our event pipeline.
        this.$eventBus.emitErrorAppErrorEvent(error, { postal, country: this.country })
      })
    },

    /**
     * On data found
     *
     * @param {object} data
     */
    async dataFound (data) {
      await this.$store.dispatch('lead/add', { key: 'address-street', value: data.Street })
      await this.$store.dispatch('lead/add', { key: 'address-city', value: data.CityName })
    },

    /**
     * On data not found
     */
    dataNotFound () {
    }
  }
}
