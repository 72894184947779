import usesApi from 'chimera/all/mixins/usesApi'
import { EventBus } from 'chimera/all/plugins/eventbus'

/**
 * Mixin to be used for API validation.
 *
 * @returns {object}
 */
export default {
  mixins: [usesApi],

  /**
   * @returns {object}
   */
  data () {
    return {
      errors: [],
      isValid: false
    }
  },

  computed: {
    /**
     * @returns {{id: string, identifier: string}}
     */
    selectedService () {
      return this.$store.state.service
    },

    /**
     * @returns {string}
     */
    country () {
      return this.$store.getters['context/get']('country', 'nl')
    }
  },

  methods: {
    /**
     * Validate field with API
     *
     * @param {string} field
     * @param {string} rawValue
     * @returns {Promise<void>}
     */
    validateField (field, rawValue) {
      const value = this.valueToString(rawValue)
      return this.apiPost(
        `field/${field}/validation/${this.country}`,
        { value },
        {
          validateStatus: (status) => {
            return status === 204
          }
        }
      )
        .then(() => {
          this.onValid(rawValue)
        })
        .catch((error) => {
          const isValidationError =
            error.response &&
            error.response.status === 422 &&
            error.response.data.value
          if (isValidationError) {
            // call onInvalid on intended faulty response status from endpoint
            // with error reason in arguments (error.response.data.value)
            this.onInvalid(error.response.data.value)
          } else {
            // Push unexpected error and meta data on our event pipeline.
            EventBus.emitErrorAppErrorEvent(error, {
              field,
              country: this.country
            })
            this.onError(rawValue)
          }
        })
    }
  }
}
