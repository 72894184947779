<template>
  <div class="form-modal">
    <div class="form-modal__top">
      <BackToLandingPageLink id="form-modal-close-button">
        <span class="form-modal__close">
          <span class="close-btn">×</span>
        </span>
      </BackToLandingPageLink>

      <slot name="progress-bar">
        <div class="form-modal__progress">
          <t-progress-bar color="primary" />
        </div>
      </slot>
    </div>

    <div class="form-modal__wrapper">
      <div class="form-modal__body fade-right">
        <div v-if="description">
          <h3 class="form-modal__description">
            {{ description }}
          </h3>
        </div>

        <div v-if="title">
          <h1 class="form-modal__title">
            {{ title }}
          </h1>
          <h2 v-if="subtitle" class="form-modal__subtitle">
            {{ subtitle }}
          </h2>
          <div class="form-modal__privacy">
            <nuxt-link
              :to="$t('global.privacyPolicyLink')"
              class="form-modal__privacy-link"
              target="_blank"
            >
              {{ $t("global.privacyPolicy") }}
            </nuxt-link>
          </div>
        </div>

        <div class="pb-4">
          <slot name="body" />
        </div>
      </div>

      <div class="form-modal__footer">
        <slot name="footer">
          <div class="footer__container">
            <t-btn
              v-if="showBackBtn"
              id="footerBackButton"
              class="footer__back-btn"
              @click="$router.back()"
            >
              {{ $t("global.back") }}
            </t-btn>
            <t-btn
              id="footerNextButton"
              :class="{
                'w-2/3': showBackBtn,
                'w-full': !showBackBtn,
              }"
              class="footer__next-btn"
              @click="$nuxt.$emit('submit')"
            >
              {{ nextBtnText || $t("global.continue") }}
            </t-btn>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import BackToLandingPageLink from 'chimera/all/themes/blueflow/components/layout/BackToLandingPageLink.vue'

export default {
  name: 'FormModal',

  components: { BackToLandingPageLink },

  props: {
    description: {
      type: String,
      default: '',
      required: false
    },

    title: {
      type: String,
      default: ''
    },

    subtitle: {
      type: String,
      default: ''
    },

    showBackBtn: {
      type: Boolean,
      default: false
    },

    nextBtnText: {
      type: String,
      default: ''
    }
  }
}
</script>
