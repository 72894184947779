<template>
  <div>
    <t-form ref="form" @submit.prevent>
      <ContactInformationFormPart @change="onChange" @result="onResult" />

      <LeadSubmitFormPartPD6492
        ref="leadSubmit"
        :is-optional="true"
        hidden
        @change="onChange"
        @leadPostError="onLeadPostError"
        @leadPostInvalid="onLeadPostInvalid"
        @leadPostValid="onLeadPostValid"
        @result="onResult"
      />
    </t-form>

    <FormErrorMessages
      v-if="errorMessages.length"
      :error-messages="customErrorMessage"
    />
  </div>
</template>

<script>
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import ContactInformationFormPart from 'chimera/all/themes/blueflow/components/form/part/contactInformation/ContactInformationFormPart'
import ErrorPage from 'chimera/all/themes/blueflow/page/ErrorPage'
import ThankYouPage from '~/pages/offertes-aanvragen/aanvraag-afgerond'
import LeadSubmitFormPartPD6492 from '~/components/form/parts/LeadSubmitFormPartPD6492.vue'

export default {
  name: 'ContactInformationFormStep',

  components: {
    LeadSubmitFormPartPD6492,
    ContactInformationFormPart,
    FormErrorMessages
  },

  extends: AbstractFormStep,

  data: () => ({
    willTransitionOnValid: true,
    customErrorMessage: ['Er ontbreken een paar gegevens'],
    experiment: null
  }),

  /**
   * Pass through the submit event given from FormModal
   */
  created () {
    this.$nuxt.$on('submit', this.onSubmit)
  },

  /**
   * Clean up before component is destroyed
   */
  destroyed () {
    this.$nuxt.$off('submit')
  },

  methods: {
    /**
     * Perform lead post.
     * Based on inner logic of `postLead` the callback will be in `onLeadPost*` methods.
     * https://bambelo.atlassian.net/browse/PD2-103
     */
    transition () {
      const postLead = this.$refs.leadSubmit.postLead

      try {
        if (!window.grecaptcha || !window.grecaptcha.ready) {
          throw new Error('Google Recaptcha is not loaded.')
        }

        window.grecaptcha.ready(async () => {
          const token = await window.grecaptcha.execute(
            this.$nuxt.context?.env?.googleRecaptchaSiteKey,
            {
              action: 'submit'
            }
          )

          this.$store
            .dispatch('lead/add', {
              key: 'recaptcha-token',
              value: token,
              origin: 'HiddenFieldInjected'
            })
            .then(() => {
              postLead()
            })
        })
      } catch (error) {
        postLead()
      }
    },

    /**
     * On successful lead post
     */
    onLeadPostValid () {
      this.routeTo(ThankYouPage)
    },

    /**
     * On invalid lead post
     */
    onLeadPostInvalid () {
      this.routeTo(ErrorPage)
    },

    /**
     * Lead post resulted in an error
     */
    onLeadPostError () {
      this.routeTo(ErrorPage)
    }
  }
}
</script>
