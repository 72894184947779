<template>
  <t-text-field
    :id="field"
    v-model="value"
    v-bind="{...$attrs}"
    :class="[`field-${field}`]"
    name="name"
    :autocomplete="autocomplete"
    :error-messages="errors"
    :placeholder="placeholder"
    outlined
    :autofocus="autofocus"
    :loading="loading"
    :success="isValid"
    @blur="validate(value)"
  />
</template>

<script>
import ValidationError from 'chimera/all/functions/ValidationError'
import AbstractFormField from 'chimera/all/components/form/fields/AbstractFormField'

export const field = 'name'
export default {
  name: 'NameField',

  extends: AbstractFormField,

  props: {
    field: {
      type: String,
      default: field
    },

    autocomplete: {
      type: String,
      default: 'name'
    },

    placeholder: {
      type: String,
      default: 'Naam'
    }
  },

  data: () => ({
    value: ''
  }),

  watch: {
    /**
     * V-model
     *
     * @param {string} value
     */
    value (value) {
      this.reset()
    }
  },

  methods: {
    /**
     * @returns {*[]}
     */
    customErrorMap () {
      return [
        { id: ValidationError.reasonIsRequired, message: this.$i18n.t('field.name.validation.required') },
        { id: ValidationError.reasonInvalidRegex, message: this.$i18n.t('field.name.validation.invalid') },
        { id: ValidationError.reasonInvalidLength, message: this.$i18n.t('field.name.validation.invalid') },
        { id: ValidationError.reasonInvalidValue, message: this.$i18n.t('field.name.validation.invalid') }
      ]
    }
  }
}
</script>
