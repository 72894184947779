<template>
  <div />
</template>

<script>
import usesExperiments from 'chimera/all/mixins/usesExperiments'
import { baseFormRoutesMap } from 'chimera/all/functions/BaseFormRoutes'
import dispatchNavigationEvents from 'chimera/all/functions/DispatchNavigationEvents'

export default {
  name: 'AbstractPage',

  mixins: [usesExperiments],

  /**
   * The callback to next in beforeRouteEnter is the last to be called when
   * resolving a route change and the only step where DOM changes and vue-meta changes
   * have been applied correctly. These things are required for our pageViewEvent
   * to be tracked correctly.
   *
   * @param {object} to
   * @param {object} from
   * @param {Function} next
   */
  async beforeRouteEnter (to, from, next) {
    await next((vm) => {
      dispatchNavigationEvents(vm, from, to)
    })
  },

  /**
   * @returns {object}
   */
  data () {
    return {
      headTitle: '',
      headDescription: '',
      headTitleTemplate: '',
      path: '/'
    }
  },

  /**
   * SEO title + meta.
   *
   * @returns {object}
   */
  head () {
    const head = {
      meta: [
        {
          property: 'og:url',
          content: this.url
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:image',
          content: `${this.rootUrl}images/og-image.jpg`
        }
      ]
    }

    if (this.headTitle) {
      head.title = this.headTitle
      head.meta.push({
        property: 'og:title',
        content: this.headTitle
      })
    }

    if (this.headTitleTemplate) {
      head.titleTemplate = this.headTitleTemplate
    }

    if (this.headDescription) {
      head.meta.push({
        hid: 'description',
        name: 'description',
        property: 'description',
        content: this.headDescription
      })
      head.meta.push({
        property: 'og:description',
        content: this.headDescription
      })
    }

    const pageIsInRobotsList = baseFormRoutesMap
      .concat(['/error'])
      .includes(`/${this.$route.fullPath.split('/')[1] ?? ''}`)

    if (pageIsInRobotsList) {
      head.meta.push({
        name: 'robots',
        content: 'noindex'
      })
    }

    return head
  },

  computed: {
    /**
     * Get concept name
     *
     * @returns {string}
     */
    concept () {
      const concept = process.env.concept
      return concept.charAt(0).toUpperCase() + concept.slice(1)
    },

    /**
     * Returns the current url
     *
     * @returns {string}
     */
    url () {
      const pathWithoutHash = this.$route.fullPath.substr(1)
      return `${this.rootUrl}${pathWithoutHash}`
    },

    /**
     * Returns the current hostname with routerBase.
     * Ends with a /
     *
     * @returns {string}
     */
    rootUrl () {
      const routerBase = this.$router.options.base
      return `${process.env.hostname}${routerBase}`
    }
  },

  /**
   */
  beforeMount () {
    window.addEventListener('beforeunload', this.emitAbandonmentEvent)
  },

  /**
   *
   */
  destroyed () {
    window.removeEventListener('beforeunload', this.emitAbandonmentEvent)
  },

  methods: {
    /**
     * Emit abandonment event
     */
    emitAbandonmentEvent () {
      this.$eventBus.emitAbandonmentEvent(this.url)
    }
  }
}
</script>
